<template>
  <div class="product-configuration-container">
    <section class="product-configuration-label title-semibold">
      {{ label }}
    </section>
    <section class="product-configuration-info-container">
      <div class="product-image">
        <img
          v-if="product.image_url"
          :src="product.image_url"
          :alt="product.image_url"
          loading="lazy"
        />
      </div>
      <div class="product-info title-medium">
        <div v-if="$slots.slotProductTitle" class="product-title">
          <slot name="slotProductTitle"></slot>
        </div>
        <div class="product-price-container">
          <!-- normal product price which means have srp price and not zero or null -->
          <span class="selling-price">
            <slot name="slotProductPrice"></slot>
          </span>
        </div>
        <div
          v-if="$slots.slotProductInfoAction"
          class="product-info-action-container"
        >
          <slot name="slotProductInfoAction"></slot>
        </div>
      </div>
      <div
        v-if="$slots.slotProductAction"
        class="product-action-container"
      >
        <slot name="slotProductAction"></slot>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'ProductConfigurationItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      isImageError: false,
    }
  },
  computed: {
    hasProductPrice() {
      return Boolean(this.product.sellingPrice)
    },
  },
  methods: {
    onImageError() {
      this.isImageError = true
    },
  },
}
</script>
<style lang="stylus" scoped>
.product-configuration-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px 16px;

  background-color: $color-gray97;
  border-radius: 8px;

  @media screen and (min-width: 1024px) {
    padding: 24px;
  }

  .product-configuration-label {
    line-height: 19px;
    letter-spacing: -0.32px;
  }

  .product-configuration-info-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 0 8px;

    @media screen and (min-width: 1024px) {
      flex-wrap: nowrap;
    }

    .product-image {
      height: 32px;
      // to fix width size of ImagePlaceholder component
      width: 32px;
      min-width: 32px;

      align-self: flex-start;

      > img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: contain;
      }
    }

    .product-info {
      flex: 1;

      display: inline-block;
      vertical-align: top;
      line-height: 22px;
      letter-spacing: -0.32px;

      .product-title,
      .product-price-container {
        display: inline;
      }

      @media screen and (min-width: 1024px) {
        .product-title,
        .product-price-container {
          display: block;
        }
      }

      .product-price-container {
        > .selling-price {
          &.-unavailable {
            color: $color-bnn;
          }
        }

        > .srp-price {
          margin-left: 8px;

          text-decoration: line-through;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }

    .product-action-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .btn {
        text-wrap: nowrap;
        width: 100%;
      }

      @media screen and (min-width: 1024px) {
        width: fit-content;

        .btn {
          width: fit-content;
        }
      }
    }
  }
}
</style>
